<template>
    
    <div id="app" v-if="$route.matched.some(({ name }) => name !== 'login')">
        <AppHeader></AppHeader>
        <Sidebar></Sidebar>
        <div class="content-area">
           <router-view/>
        </div>
    </div>
    <div id="app" v-else>
        <router-view/>
    </div>
</template>

<script>
import AppHeader from './Header'
import Sidebar from './Sidebar'
export default {
    components:{
        AppHeader,
        Sidebar
    }
}
</script>

<style lang="scss" >

$body-bg: #ffffff;
$body-color: #4a4a4a;
$primary:#00b1f4 !default;
$enable-rounded:false;

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/bootstrap-vue/src/index.scss';
@import '../../node_modules/nprogress/nprogress.css';
body.loginpage{
  background-image: url('~@/assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
html, body{
    min-height: 100%;
    padding: 0;
}
body{
    padding: 0 !important;
}
.content-area{
    
    height: calc(100vh - 70px);
    overflow: auto;
    margin-left: 240px;
    overflow: auto;
    background-color: #f8f8fa;
    padding: 15px 30px;
}
#app {
  font-family: 'Roboto Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #5b626b;
  padding-top: 70px;
}
.page-title{
    font-size: 20px;
    margin: 0;
    line-height: 1.9;
}
.card.cst-body{
    border-radius: 7px;
    border:1px solid rgba(0,0,0,.125);
    text-align: left;
    .card-title{
        font-size: 16px;
        
    }
    .card-subtitle{
        color: #9ca8b3!important;
        font-weight: normal;
        margin-bottom: 30px !important;
        font-size: 14px;
    }
}
.cst-dropdown{
    .dropdown-menu {
        padding: 4px 0;
        font-size: 14px;
        -webkit-box-shadow: 0 0 13px 0 rgba(236,236,241,.44);
        box-shadow: 0 0 13px 0 rgba(236,236,241,.44);
        background-color: #fff;
        border-color: #e9ecef;
        margin: 0;
        line-height: 1.4;
       
    }
    .ditem{
        a{
        padding-bottom: 7px;
        padding-top: 7px;
        }
        
    }
  }
 .custom-control-label{
     font-size: 16px !important;
 }
 .delete-btn-wrapper{
     cursor: pointer;
 }
.delete-btn{
   margin-left: 25px;
   font-size: 25px;
   display: inline-block;
   vertical-align: middle !important;
 }
 .inlineblock{
     display: inline-block !important;
 }
</style>