<template>
    <div class="left side-menu">
        <div id="sidebar-menu" class="mm-active">
        <!-- Left Menu Start -->
            <ul class="metismenu mm-show" id="side-menu">
                <li>
                    <b-link to="/dashboard" class="sidemenulink">
                        <b-icon-people></b-icon-people> Manage Users
                    </b-link>
                </li>
                <li>
                    <b-link to='/favoritecategories' class="sidemenulink">
                        <b-icon-star></b-icon-star> Favorite Categories
                    </b-link>
                </li>
                <li>
                    <b-link to="/favoritelinks" class="sidemenulink">
                        <b-icon-star></b-icon-star> Favorite Links
                    </b-link>
                </li>
                <li>
                    <b-link to="/feedcategories" class="sidemenulink">
                        <svg id="rssfeed" enable-background="new 0 0 24 24" height="13" viewBox="0 0 24 24" width="13" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="3.429" cy="20.571" r="3.429"/>
                            <path d="m11.429 24h4.57c0-8.821-7.178-15.999-15.999-16v4.572c6.302.001 11.429 5.126 11.429 11.428z"/>
                            <path d="m24 24c0-13.234-10.766-24-24-24v4.571c10.714 0 19.43 8.714 19.43 19.429z"/>
                        </svg> Feed Categories
                    </b-link>
                </li>
                <li>
                    <b-link to="/feedurl" class="sidemenulink">
                        <svg id="rssfeed" enable-background="new 0 0 24 24" height="13" viewBox="0 0 24 24" width="13" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="3.429" cy="20.571" r="3.429"/>
                            <path d="m11.429 24h4.57c0-8.821-7.178-15.999-15.999-16v4.572c6.302.001 11.429 5.126 11.429 11.428z"/>
                            <path d="m24 24c0-13.234-10.766-24-24-24v4.571c10.714 0 19.43 8.714 19.43 19.429z"/>
                        </svg> Feed Url
                    </b-link>
                </li>
                <li>
                    <b-link to="/suggestioncategories" class="sidemenulink">
                        <b-icon-star></b-icon-star> Suggestion Categories
                    </b-link>
                </li>
                <li>
                    <b-link to="/suggestionlink" class="sidemenulink">
                        <b-icon-star></b-icon-star> Suggestion URL
                    </b-link>
                </li>
                <li>
                    <b-link to="/managecountry" class="sidemenulink">
                        <b-icon-star></b-icon-star> Manage Country
                    </b-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'sidebar'
}
</script>
<style lang="scss" scoped>
    .side-menu {
        width: 240px;
        z-index: 10;
        background: #333547;
        bottom: 0;
        margin-top: 0;
        padding-bottom: 30px;
        position: fixed;
        top: 70px;
        #rssfeed{
            fill: #8699ad;
        }
        .sidemenulink{
            color: #8699ad;
            &:hover{
                color: #b4c9de;
                text-decoration: none;
                #rssfeed{
                    fill: #b4c9de;
                }
            }
            svg{
                margin-right: 5px !important;
                font-size: 19px;
            }
        }
    }
    #side-menu{
        padding: 0;
        padding-top: 30px;
        margin: 0;
        list-style: none;
        li{
            text-align: left;
            padding-left: 0;
            
            a{
                padding: 8px 0;
                display: block;
                position: relative;
                padding-left: 15px;
                &.router-link-active{
                    color: #b4c9de;
                    &:before{
                        content: '';
                        width: 0; 
                        height: 0; 
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent; 
                        
                        border-right:10px solid #f8f8fa; 
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }
    }
</style>
