<template>
  <div class="topbar">
    <!-- LOGO -->
    <div class="topbar-left">
        <b-navbar-brand class="logo"><span>MyPage Admin</span></b-navbar-brand>
    </div>
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-nav class="ml-auto">
        <b-nav-text class="username">Welcome back <span>{{userDetail.name}}</span></b-nav-text>
        <b-nav-item-dropdown right class="cst-dropdown">
          <template v-slot:button-content>
            <img :src="userImg" class="userpic" />
          </template>
          <b-dropdown-item class="ditem" href="#">
            <b-icon-person-fill style="margin-right:5px; font-size:18px;"></b-icon-person-fill> Profile
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout" class="ditem" href="javascript:void(0)" variant="danger"><b-icon-power style="margin-right:5px; font-size:18px;"></b-icon-power> Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import userImg from '../assets/user-4.jpg'
export default {
  name: 'Header',
  components:{
    
  },
  data:function(){
    return{
      userDetail:{},
      userImg: userImg
    }
  },
  methods:{
    ...mapActions('login',{
       logout: 'logout'
     })
  },
  created:function(){
    let vm  = this
    vm.userDetail = vm.$jwt.decode(vm.$jwt.getToken()).userDetail
    
  }
  
}
</script>
<style lang="scss" scoped>
  .bg-light{
    background-color: #ececf1 !important;
    background-color: #ececf1;
    border-radius: 0;
    margin-bottom: 0;
    padding: 0 10px 0 0;
    margin-left: 240px;
    min-height: 70px;
  }
  .logo{
    color: #ffffff;
    line-height: 70px;
    padding: 0;
  }
  .navbar{
    padding-top:0;
    padding-bottom: 0; 
  }
  .cst-dropdown{
    padding: 8px 15px 0;
    color: #525f80;
    display: block;
  }
  .topbar {
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 999;
      .topbar-left {
          background: #333547;
          float: left;
          text-align: center;
          height: 70px;
          position: relative;
          width: 240px;
          z-index: 1;
      }
  }
  .navbar-light{
    .navbar-text{
      &.username{
        font-weight: 300;
        line-height: 70px;
        padding-top: 0;
        padding-bottom: 0;
        span{
          font-weight: 400;
        }
      }
    }
    .userpic{
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
 
</style>
