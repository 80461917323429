export default {
    errorgetter: (state) => state.errormsg,
    isBusy: (state) => state.isBusy,
    countryDrowpdown: (state) =>{
      const finalcountry = state.countries.map((item) => {
        return {value:item.id, text:item.name}
      })
      return finalcountry;
    },
    countrymasterlist: (state) =>{
      const finalcountry = state.countrymaster.map((item) => {
        return item.name
      })
      return finalcountry;
    }
    
  }