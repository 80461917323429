<template>
  <div class="home">
      <b-row style="margin-bottom:15px;">
        <b-col class="text-left"><h2 class="page-title">Favorite Category</h2></b-col>
        <b-col class="text-right">
          <b-button v-b-modal.addcategorymodal variant="info" size="sm">Add New Category</b-button>
        </b-col>
      </b-row>
      
      <b-card class="cst-body" title="Manage Favorite Category" sub-title="Below are the list of favorite category, you can enable, disable and delete particular category.">
        <b-card-text>
          <div class="overflow-auto">
            

            <b-table
              id="favoritecat"
              :current-page="currentPage"
              :busy.sync="isFetching"
              :items="favcatList"
              :per-page="perPage"
              :fields="fields">
              
              <template v-slot:cell(isActive)="row">
              
              <b-form-checkbox class="inlineblock" @change="enabledisableCat(row.item)" v-model="row.item.isActive" switch size="lg">
                {{row.item.isActive?'Enable':'Disabled'}}
              </b-form-checkbox>
              <span class="delete-btn-wrapper" @click="deleteConfirmation(row.item.id)"><b-icon-trash-fill class="delete-btn" variant="danger"></b-icon-trash-fill>Delete</span>
              <span class="delete-btn-wrapper" @click="editHandler(row.item)"><b-icon-pencil class="delete-btn" variant="info"></b-icon-pencil>Edit</span>
            </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="favoritecat"
            ></b-pagination>
          </div>
        </b-card-text>
      </b-card>
      <addcategory modaltitle="Add Favorite Category" :type="1" :addcategoryHandler="addcategoryHandler"></addcategory>
      <editcategory modaltitle="Edit Favorite Link"
        :edititem="edititem" 
        :updateFavcat="updateFavcat"></editcategory>
  </div>
</template>

<script>
// @ is an alias to /src
//import { mapState } from 'vuex'
import { mapActions, mapState } from 'vuex'
import addcategory from '../components/Addcategory'
import editcategory from '../components/Editcategory'

export default {
  name: 'favoritecategories',
  components: {
    addcategory: addcategory,
    editcategory: editcategory
  },
  data:function(){
    return {
      edititem:{
        title: '',
        id: 1
      },
      userDetail:{},
      isFetching: false,
      currentPage: 1,
      perPage: 10,
      fields:[
        { key: 'title', label: 'Favorite Heading' },
        { key: 'isActive', label: 'Action' },
      ],
      confirmdelete: '',
    }
  },
  computed:{
    ...mapState('favoritecat',{
      isBusy: state => state.isBusy,
      favcatList: state => state.favcatList
    }),
    rows() {
        return this.favcatList.length
      }
    
  },
  watch:{
    isBusy:function(){
      this.$root.$emit('bv::refresh::table', 'favoritecat')
    }
  },
  mounted(){
    this.favcatProvider(1)
  },
  methods:{
     ...mapActions('favoritecat',{
       favcatProvider: 'favcatProvider',
       updateFavcat: 'updateFavcat',
       addcategoryHandler: 'addcategory',
       deleteCategory: 'deleteCategory'
     }),
     editHandler:function(item){
       this.edititem = item;
       this.$bvModal.show('editcategorymodal')
     },
     enabledisableCat(item){
       this.updateFavcat({vmp:this, type:1, payload:item})
     },
     deleteConfirmation(item) {
        this.confirmdelete = ''
        this.$bvModal.msgBoxConfirm('Are you sure you want to permanently remove this item?')
          .then(value => {
            if(value){
              this.deleteCategory({vmp:this,type:1, payload:{"id":item}})
            }
          })
      },
  },
  created:function(){
    let vm  = this
    
    vm.userDetail = vm.$jwt.decode(vm.$jwt.getToken()).userDetail
    
  }
}
</script>
<style lang="scss" scoped>
 
</style>