export default {
    errorgetter: (state) => state.errormsg,
    isBusy: (state) => state.isBusy,
    feedcatdropdwonlist: (state) =>{
      const catoption = state.feedcatList.map((item) => {
        return {value:item.id, text:item.title}
      })
      return catoption;
    }
    
  }