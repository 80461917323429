

export default {
        isBusy: false,
        loading: false,
        error: false,
        status: '',
        message:'',
        countrymaster:[],
        countries: []
}