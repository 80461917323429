import { GET_FAV_LINK, ENABLE_DISABLE_FAV_LINK, ADD_LINK } from './constants'

export default {
    [`${GET_FAV_LINK}_REQUEST`] (state) {
        state.loading = true
        state.isBusy = true
        
        
    },
    [`${GET_FAV_LINK}_SUCCESS`](state, payload){
        state.loading = false
        state.items = payload
        state.isBusy = false
        state.status = payload.status
        state.message = payload.result
    },
    
    [`${GET_FAV_LINK}_ERROR`](state, payload){
        state.loading = false
        
        state.status = payload.data.status
        state.message = payload.data.result.message
    },


    [`${ADD_LINK}_REQUEST`] (state) {
        state.loading = true
        state.isBusy = true
        
    },
    [`${ADD_LINK}_SUCCESS`](state){
        state.loading = false
        state.isBusy = false
    },
    
    [`${ADD_LINK}_ERROR`](state){
        state.loading = false
        
    },


    [`${ENABLE_DISABLE_FAV_LINK}_REQUEST`] (state) {
        state.loading = true
    },
    [`${ENABLE_DISABLE_FAV_LINK}_SUCCESS`](state, payload){
        state.loading = false
        
        state.status = payload.status
        state.message = payload.result
    },
    
    [`${ENABLE_DISABLE_FAV_LINK}_ERROR`](state, payload){
        state.loading = false
        state.status = payload.data.status
        state.message = payload.data.result.message
    },

}

