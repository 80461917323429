<template>
  <div class="loginctrl">
      <div class="login-resize">
        <h1>MyPage Admin</h1>
        <div v-if="errormsg && errormsg.data">{{errormsg.data.result.message}}</div>
        <b-form @submit="onSubmit" class="text-left" novalidate>
            <b-form-group
              id="email-group"
              label="Email address:"
              label-for="emailid"
            >
              <b-form-input
                id="input-1"
                type="email"
                @keyup="()=> this.isemailblank = true"
                placeholder="Enter email"
                v-model="form.userName"
                :state="isemailblank"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                id="input-1-live-feedback">This field is required.</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="password-group"
              label="Password:"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                type="password"
                required
                placeholder="********"
                @keyup="()=> this.ispasswordblank = true"
                :state="ispasswordblank"
                aria-describedby="password"
              ></b-form-input>
              <b-form-invalid-feedback
                id="password">This field is required.</b-form-invalid-feedback>
            </b-form-group>
            <div class="text-center btn-section">
              <b-button type="submit" variant="primary">Submit</b-button>
            </div>
            
        </b-form>
      </div>
    
  </div>
</template>


<script>
import { mapState } from 'vuex'
export default {
  name: 'login',
  data:function(){
    return {
      form: {
          userName: '',
          password: ''
        },
        isemailblank: true,
        ispasswordblank:true
    }
  },
  
  methods:{
    
    onSubmit(evt) {
        evt.preventDefault()
        if (this.form.userName =='') {
          this.isemailblank = false
          return
        }else{
          this.isemailblank = true
        }
        if (this.form.password =='') {
          this.ispasswordblank = false
         return
        }else{
          this.ispasswordblank = true
        }
        this.$store.dispatch('login/login', this.form)
        
       // console.log(JSON.stringify(this.form))
      },
      
  },
  computed: {
    ...mapState('login',{
      errormsg: 'errormsg',
    })
  },
  beforeCreate:function(){
    let bodyel = document.body
    bodyel.className += 'loginpage'
  },
  created:function(){
    
  }
}
</script>
<style lang="scss" scoped>
  $enable-rounded:false;
  .loginctrl{
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(#000000, 0.8);
    overflow: auto;
  }
  .login-resize{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    max-width: 430px;
    width: 90%;
    padding: 40px 65px;
  }
  h1{
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 35px;
  }
  .btn-section{
    padding-top: 15px;
    button{
      min-width: 188px;
    }
  }
  .form-control.is-valid{
    background-image: none;
    border-color: #ced4da;
    &:focus{
      box-shadow: 0 0 0 0.2rem rgba(0, 177, 244, 0.25);
    }
  }
</style>