import { LOGOUT, LOGIN } from './constants'

export default {
    [`${LOGIN}_REQUEST`] (state) {
        state.loading = true
    },
    [`${LOGIN}_SUCCESS`](state, token){
        state.loading = false
        state.isAuthenticated = true
        state.token = token
    },
    
    [`${LOGIN}_ERROR`](state, payload){
        state.errormsg = payload
        state.error = true
    },
    [LOGOUT](state){
        state.loading = false
        state.token = ''
    },

}

