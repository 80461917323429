import { ENABLE_DISABLE_USER } from './constants'

export default {
    [`${ENABLE_DISABLE_USER}_REQUEST`] (state) {
        state.loading = true
    },
    [`${ENABLE_DISABLE_USER}_SUCCESS`](state, payload){
        state.loading = false
        state.status = payload.status
        state.message = payload.result
        alert()
    },
    
    [`${ENABLE_DISABLE_USER}_ERROR`](state, payload){
        state.loading = false
        state.status = payload.data.status
        state.message = payload.data.result.message
    },

}

