//import {API_BASE_URL} from '../../../global-constant'
import {UPDATE_COUNTRY, GET_COUNTRY, ADD_COUNTRY, DELETE_COUNTRY, GET_COUNTRY_DROPDOWN } from './constants'
import Api from '../../../services/api'
import axios from 'axios'
export default {
    updatecountry({commit, dispatch}, {vmp, payload}){
        return new Promise((resolve, reject) => {
            commit(`${UPDATE_COUNTRY}_REQUEST`)
            Api()({url: `/update-country`, data: payload, method: 'PUT' })
            .then(resp => {
                const msg = resp.data.result.message
                vmp.$bvToast.toast(msg, {
                    title : 'OK',
                    variant : 'success',
                    solid : true,
                })
                dispatch('countryProvider', vmp)
                commit(`${UPDATE_COUNTRY}_SUCCESS`, msg)
                resolve(resp)
            })
            .catch(err => {
                commit(`${UPDATE_COUNTRY}_ERROR`)
                const msg = err.response.data.result.message
                vmp.$bvToast.toast(msg, {
                    title : 'Error',
                    variant : 'danger',
                    solid : true,
                })
                reject(err)
            })
        })
    },
    countryProvider({commit},{vmp}){
        return new Promise((resolve, reject) => {
            commit(`${GET_COUNTRY}_REQUEST`)
            let promise = Api().get(`/get-country-list`)
            promise.then((res) => {
                const items = res.data.result.data
                commit(`${GET_COUNTRY}_SUCCESS`, items)
                resolve(res)
            }).catch(err => {
                commit(`${GET_COUNTRY}_ERROR`)
                const msg = err.response.data.result.message
                vmp.$bvToast.toast(msg, {
                    title : 'Error',
                    variant : 'danger',
                    solid : true,
                })
                reject(err)
            })
        })
    },
    getCountrydropdown({commit},payload){
        return new Promise((resolve, reject) => {
            
            let promise = axios.get(`https://restcountries.eu/rest/v2/name/${payload}`)
            promise.then((res) => {
                const items = res.data
               // console.log(items)
                commit(`${GET_COUNTRY_DROPDOWN}_SUCCESS`, items)
                resolve(res)
            }).catch(err => {
                
                reject(err)
            })
        })
    },
    addcountry({commit, dispatch}, {vmp, payload}){
        return new Promise((resolve, reject) => {
            commit(`${ADD_COUNTRY}_REQUEST`)
            let promise = Api()({url: `/add-country`, data: payload, method: 'post' })
            return promise.then((resp) => {
                const msg = resp.data.result.message
                commit(`${ADD_COUNTRY}_SUCCESS`)
                vmp.$bvToast.toast(msg, {
                    title : 'OK',
                    variant : 'success',
                    solid : true,
                })
                dispatch('countryProvider', vmp)
                resolve(resp)
            }).catch(err => {
                const msg = err.response.data.result.message
                vmp.$bvToast.toast(msg, {
                    title : 'Error',
                    variant : 'danger',
                    solid : true,
                })
                commit(`${ADD_COUNTRY}_ERROR`)
                reject(err)
            })
        })
    },
    deleteCountry({commit,dispatch}, {vmp, payload}){
        return new Promise((resolve, reject) => {
            commit(`${DELETE_COUNTRY}_REQUEST`)
            let promise = Api()({url: `/delete-country`, data: payload, method: 'delete' })
            return promise.then((resp) => {
                commit(`${DELETE_COUNTRY}_SUCCESS`)
                const msg = resp.data.result.message
                vmp.$bvToast.toast(msg, {
                    title : 'OK',
                    variant : 'success',
                    solid : true,
                })
                dispatch('countryProvider', vmp)
                resolve(resp)
            }).catch(err => {
                const msg = err.response.data.result.message
                commit(`${DELETE_COUNTRY}_ERROR`)
                vmp.$bvToast.toast(msg, {
                    title : 'Error',
                    variant : 'danger',
                    solid : true,
                })
                reject(err)
            })
        })
    },
}