import axios from 'axios';
import {API_BASE_URL} from '../global-constant'
import NProgress from 'nprogress'
/* export default () => {
  
  
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {'Authorization': "bearer " + localStorage.getItem('auth_token')}
  });
} */

const Api = axios.create({
  baseURL: API_BASE_URL,
  headers: {'Authorization': "bearer " + localStorage.getItem('auth_token')}
});

Api.interceptors.request.use(config => {
  NProgress.start()
  return config
})

// before a response is returned stop nprogress
Api.interceptors.response.use(response => {
  NProgress.done()
  return response
})


/* Api.interceptors.response.use(null, error => {
  let path = 'error';
  switch (error.response.status) {
    case 401: path = 'login'; break;
    case 404: path = '404'; break;
  }
  router.history.go({name:path})
}); */

export default () => {
 return Api
}