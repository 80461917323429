<template>
    <b-modal
      id="editcategorymodal"
      ref="modal"
      :title="modaltitle"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="titleState"
          label="Category Name"
          label-for="title-input"
          invalid-feedback="Title is required"
        >
          <b-form-input
            id="title-input"
            v-model="form.title"
            :state="titleState"
            required
          ></b-form-input>
          
        </b-form-group>
      </form>
    </b-modal>
</template>
<script>

export default {
    name: 'editcategory',
    props: ['modaltitle','edititem', 'updateFavcat'],
    data() {
      return {
        form:{
            title: null,
            id: null
        },
        titleState: null,
      }
    },
    watch:{
    edititem:function(){
      const vm = this;
      vm.form = {...vm.edititem}
    }
  },
  
    methods:{
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            return valid
        },
      resetModal() {
        this.$nextTick(() => { this.$v.$reset(); })
      },
      handleOk(bvModalEvt) {
        
        bvModalEvt.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
        let vm = this;
        if (!this.checkFormValidity()) {
          return
        }
        vm.updateFavcat({vmp:this,type:1,payload:vm.form})
        this.$nextTick(() => {
          this.$bvModal.hide('editcategorymodal')
        })
      }
    }
}
</script>