<template>
  <div class="home">
      <b-row style="margin-bottom:15px;">
        <b-col class="text-left"><h2 class="page-title">Feed URL</h2></b-col>
        <b-col class="text-right">
          <b-button @click="addnewLink" variant="info" size="sm">Add New Feed URL</b-button>
        </b-col>
      </b-row>
      
      <b-card class="cst-body" title="Manage Feed URL" sub-title="Below are the list of feed url, you can enable, disable and delete particular url.">
        <b-card-text>
          <div class="overflow-auto">
            <b-container fluid class="bv-example-row">
            <b-row align-h="end" style="margin-bottom:25px;">
              <b-col cols="3">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search">
                  </b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            </b-container>
            <b-table
              id="favoritelink"
              :busy.sync="isFetching"
              :items="items"
              :per-page="perPage"
              :filter="filter"
              :current-page="currentPage"
              :fields="fields">
              <template v-slot:cell(isActive)="row">
              
              <b-form-checkbox class="inlineblock" @change="enableDisablelink(row.item)" v-model="row.item.isActive" switch size="lg">{{row.item.isActive?'Enable':'Disabled'}}</b-form-checkbox>
              <span class="delete-btn-wrapper" @click="deleteConfirmation(row.item.id)"><b-icon-trash-fill class="delete-btn" variant="danger"></b-icon-trash-fill>Delete</span>
              <span class="delete-btn-wrapper" @click="editHandler(row.item)"><b-icon-pencil class="delete-btn" variant="info"></b-icon-pencil>Edit</span>
            </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="favoritelink"
            ></b-pagination>
          </div>
        </b-card-text>
      </b-card>
      <addlink modaltitle="Add Feed URL" :type="2" :handleSubmit="handleAddlink" ></addlink>
      <editlink modaltitle="Edit Feed URL"
        :edititem="edititem" 
        :type="2" 
        :updateFavlink="updateFavlink"></editlink>
  </div>
</template>

<script>
// @ is an alias to /src
//import { mapState } from 'vuex'
import { mapActions, mapState } from 'vuex'
import addfeedlink from '../components/Addfeedlink'
import editfeedlink from '../components/Editfeedlink'

export default {
  name: 'feedurl',
  components: {
    addlink:addfeedlink,
    editlink:editfeedlink
  },
  data:function(){
    return {
      userDetail:{},
      filter: '',
      edititem: {},
      isFetching: false,
      currentPage: 1,
      isEdit:false,
      perPage: 10,
      fields:[
        { key: 'country', label: 'Country' },
        { key: 'title', label: 'Feed Title' },
        { key: 'url', label: 'Feed URL' },
        { key: 'category', label: 'Feed Category' },
        { key: 'isActive', label: 'Action' },
      ]
    }
  },
  
  computed:{
    ...mapState('favoritelink',{
      isBusy: state => state.isBusy,
      items: state => state.items,
    }),
    
    
    rows() {
        return this.items.length
      }
    
  },
  watch:{
    isBusy:function(){
      
      this.$root.$emit('bv::refresh::table', 'favoritelink')
    }
  },
  methods:{
     
     ...mapActions('favoritelink',{
       favlinkProvider: 'favlinkProvider',
       handleAddlink: 'addLinkhandler',
       updateFavlink: 'updateFavlink',
       deleteLink: 'deleteLink'
     }),
     
     enableDisablelink: function(item){
       this.updateFavlink({type:2,vmp:this, payload:item})
     },
     editHandler:function(item){
       this.edititem = item;
       this.$bvModal.show('editlinkmodal')
     },
     addnewLink:function(){
      
       this.$bvModal.show('addlinkmodal')
     },     
     deleteConfirmation(item) {
        this.confirmdelete = ''
        this.$bvModal.msgBoxConfirm('Are you sure you want to permanently remove this item?')
          .then(value => {
            if(value){
              this.deleteLink({type:2,vmp:this,payload:{"id":item}})
            }
          })
      },
  },
  
  mounted:function(){
    this.favlinkProvider(2)
  },
  created:function(){
    let vm  = this
    
    vm.userDetail = vm.$jwt.decode(vm.$jwt.getToken()).userDetail
    
  }
}
</script>
<style lang="scss" scoped>
 
</style>