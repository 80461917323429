<template>
  <b-modal
      id="addcountrymodal"
      ref="modal"
      :title="modaltitle"
      @show="resetModal"
      @hidden="resetModal"
      @hide="reseteditdata"
      @ok="handleOk"
    >
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group id="titlegroup" label="Country Name" label-for="title">
        <!-- <b-form-input
          id="title"
          name="name"
          v-model="$v.form.name.$model"
          @keyup="getCountrydropdown($v.form.name.$model)"
          :state="validateState('name')"
          aria-describedby="name-feedback"
        ></b-form-input> -->
        <vue-bootstrap-typeahead
          v-model="$v.form.name.$model"
          :data="countrymaster"
          :state="validateState('name')"
          :serializer="s => s.name"
          @hit="selectedCountry = $event"
          ref="townTypeahead"
          aria-describedby="name-feedback"
        />

        <b-form-invalid-feedback
          id="name-feedback"
        >This is required field.</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="countrycode" label="Country Code" label-for="countrycode">
        <b-input-group>
          <b-form-input
            id="countrycode"
            name="code"
            v-model="$v.form.code.$model"
            :state="validateState('code')"
            aria-describedby="code-feedback"
          ></b-form-input>

        </b-input-group>
        <b-form-invalid-feedback
          id="code-feedback"
        >This is required field.</b-form-invalid-feedback>
      </b-form-group>
     
    </b-form>
  
  </b-modal>
</template>

<style>
body {
  padding: 1rem;
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapState } from 'vuex'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
  mixins: [validationMixin],
   components:{
    VueBootstrapTypeahead
  },
  props: ['modaltitle', 'handleSubmit', 'editdata', 'reseteditdata'],
  data() {
    return {
      selectedCountry: null,
      form: {
        name: null,
        code: null,
        isDefault: false
      }
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      code: {
        required,
      },
      isDefault:{}
    }
  },
 
  watch:{
    editdata:function(){
      this.form = {...this.editdata}
      
    },
    name:function(){
      if(this.name !='' && this.name != null){
        this.getCountrydropdown(this.name)
      }
      
    },
    selectedCountry:function(){
      this.form.code = this.selectedCountry.alpha2Code
    }
  },
  computed:{
    name(){
      return this.form.name
    },
    ...mapState('country',{
      countrymaster: state => state.countrymaster
    }),
    ...mapGetters('country',{
      countrymasterlist: 'countrymasterlist',
    }),
  },
  mounted(){
    //this.$refs.townTypeahead.inputValue = this.form.name || ''
    
  },
  methods: {
    ...mapActions('country',{
       addcountry: 'addcountry',
       updatecountry: 'updatecountry',
       getCountrydropdown: 'getCountrydropdown'
     }),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetModal() {
      this.form = {
        name: null,
        code: null,
        isDefault:false
      };
      
     this.$nextTick(() => { this.$v.$reset(); })
    },
    handleOk(bvModalEvt) {
        
        bvModalEvt.preventDefault()
        this.onSubmit()
      },
    onSubmit() {
      const vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if(vm.editdata.name != null){
        this.updatecountry({vmp:this, payload:vm.form})
      }else{
       vm.addcountry({vmp:this, payload:vm.form})
      }
      //console.log(vm.form)
      //vm.addcountry({vmp:this, payload:vm.form})
      this.$nextTick(() => {
          this.$bvModal.hide('addcountrymodal')
        })
    }
  },
 updated(){
   if(this.$refs.townTypeahead && this.form.name != null){
     this.$refs.townTypeahead.inputValue = this.form.name;
   }
   
 },
  created:function(){
   
  }
};
</script>