import {UPDATE_COUNTRY, GET_COUNTRY, ADD_COUNTRY, DELETE_COUNTRY, GET_COUNTRY_DROPDOWN } from './constants'

export default {
    [`${GET_COUNTRY}_REQUEST`] (state) {
        state.loading = true
        state.isBusy = true
        
        
    },
    [`${GET_COUNTRY}_SUCCESS`](state, payload){
        state.loading = false
        state.countries = payload
        state.isBusy = false
    },
    
    [`${GET_COUNTRY}_ERROR`](state,){
        state.loading = false
    },
    [`${UPDATE_COUNTRY}_REQUEST`] (state) {
        state.loading = true
    },
    [`${UPDATE_COUNTRY}_SUCCESS`](state){
        state.loading = false
    },
    
    [`${UPDATE_COUNTRY}_ERROR`](state){
        state.loading = false
    },
    [`${ADD_COUNTRY}_REQUEST`] (state) {
        state.loading = true
    },
    [`${ADD_COUNTRY}_SUCCESS`](state){
        state.loading = false
    },
    [`${ADD_COUNTRY}_ERROR`](state){
        state.loading = false
    },
    [`${DELETE_COUNTRY}_REQUEST`] (state) {
        state.loading = true
    },
    [`${DELETE_COUNTRY}_SUCCESS`](state){
        state.loading = false
    },
    [`${DELETE_COUNTRY}_ERROR`](state){
        state.loading = false
    },
    [`${GET_COUNTRY_DROPDOWN}_SUCCESS`](state,payload){
        state.countrymaster = payload
    },

}

