import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Dashboard from '../views/Dashboard.vue'
import Favoritecategories from '../views/Favoritecategories.vue'
import Favoritelinks from '../views/Favoritelinks.vue'
import Feedlinks from '../views/Feedlinks.vue'
import Feedcategories from '../views/Feedcategories.vue'
import Suggestioncategories from '../views/Suggestioncategories.vue'
import Suggestionlink from '../views/Suggestionlink'
import Country from '../views/Country.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: { 
      guest: true
  }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/favoritecategories',
    name: 'favoritecategories',
    component: Favoritecategories,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/favoritelinks',
    name: 'favoritelinks',
    component: Favoritelinks,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/feedcategories',
    name: 'feedcategories',
    component: Feedcategories,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/feedurl',
    name: 'feedurl',
    component: Feedlinks,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/suggestioncategories',
    name: 'suggestioncategories',
    component: Suggestioncategories,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/suggestionlink',
    name: 'suggestionlink',
    component: Suggestionlink,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/managecountry',
    name: 'country',
    component: Country,
    meta: { 
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})


//const isAuthenticate = window.localStorage.getItem('auth_token')
const isAuthenticate = store.state.login.isAuthenticated;
//console.log('isAuthenticate', isAuthenticate)
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticate) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
    } else {
      //let user = JSON.parse(localStorage.getItem('user'))
       next()
    }
  } else if(to.matched.some(record => record.meta.guest)) {
      if(!isAuthenticate){
          next()
      }
      else{
          next({ name: 'dashboard'})
      }
    }else {
    next() 
  }
})

export default router
