<template>
    <b-modal
      id="addcategorymodal"
      ref="modal"
      :title="modaltitle"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="titleState"
          label="Category Name"
          label-for="title-input"
          invalid-feedback="Title is required"
        >
          <b-form-input
            id="title-input"
            v-model="form.title"
            :state="titleState"
            required
          ></b-form-input>
          
        </b-form-group>
      </form>
    </b-modal>
</template>
<script>

export default {
    name: 'addcategory',
    props: ['modaltitle', 'addcategoryHandler', 'type'],
    data() {
      return {
        form:{
            title: null,
            type: this.type
        },
        titleState: null,
      }
    },
    methods:{
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            return valid
        },
      resetModal() {
        this.title = ''
        this.titleState = null
      },
      handleOk(bvModalEvt) {
        
        bvModalEvt.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
        let vm = this;
        if (!this.checkFormValidity()) {
          return
        }
        vm.addcategoryHandler({vmp:this,type:vm.type,payload:vm.form})
        this.$nextTick(() => {
          this.$bvModal.hide('addcategorymodal')
        })
      }
    }
}
</script>