import {API_BASE_URL} from '../../../global-constant'
import {LOGIN, LOGOUT } from './constants'
import router from '../../../router'

import axios from 'axios'

export default {
    login({commit}, user){
        return new Promise((resolve, reject) => {
        commit(`${LOGIN}_REQUEST`)
        axios({url: `${API_BASE_URL}/login`, data: user, method: 'POST' })
        .then(resp => {
            const token = resp.data.result.token
            //const user = resp.data.user
            localStorage.setItem('auth_token', token)
            axios.defaults.headers.common['Authorization'] = token
            commit(`${LOGIN}_SUCCESS`, token)
            router.history.go({name:'dashboard'})
            resolve(resp)
            //alert(resp)
        })
        
        .catch(err => {
            commit(`${LOGIN}_ERROR`, err.response)
            console.log(err.response)
            localStorage.removeItem('token')
            reject(err)
        })
        })
    },
    logout({commit}){
        return new Promise((resolve) => {
          commit(LOGOUT)
          window.localStorage.removeItem('auth_token')
          delete axios.defaults.headers.common['Authorization']
          router.history.go({name:'login'})
          resolve()
        })
        
      }
}