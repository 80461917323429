//import {API_BASE_URL} from '../../../global-constant'
import {ENABLE_DISABLE_FAV_LINK, GET_FAV_LINK } from './constants'

import Api from '../../../services/api'
export default {
    updateFavlink({commit, dispatch}, {type, vmp, payload}){
        return new Promise((resolve, reject) => {
        commit(`${ENABLE_DISABLE_FAV_LINK}_REQUEST`)
        Api()({url: `/update-content`, data: payload, method: 'PUT' })
        .then(resp => {
            const msg = resp.data.result.message
            vmp.$bvToast.toast(msg, {
                title : 'OK',
                variant : 'success',
                solid : true,
              })
            
            dispatch('favlinkProvider',type)
            commit(`${ENABLE_DISABLE_FAV_LINK}_SUCCESS`, msg)
            resolve(resp)
        })
        .catch(err => {
            commit(`${ENABLE_DISABLE_FAV_LINK}_ERROR`, err.response)
            console.log(err.response)
            reject(err)
        })
        })
    },
    favlinkProvider({commit}, payload){
        commit(`${GET_FAV_LINK}_REQUEST`)
        let promise = Api().get(`/get-content-list?type=${payload}`)
        return promise.then((res) => {
            const items = res.data.result.data
            
            commit(`${GET_FAV_LINK}_SUCCESS`, items)
            
        }).catch(error => {
            commit(`${GET_FAV_LINK}_ERROR`, error.response)
          console.log(error)
          this.isBusy
          return []
        })
    },
    addLinkhandler({dispatch}, {vmp,payload,type}){
        let promise = Api()({url: `/add-content`, data: payload, method: 'post' })
        return promise.then((resp) => {
            
            const msg = resp.data.result.message
            vmp.$bvToast.toast(msg, {
                title : 'OK',
                variant : 'success',
                solid : true,
              })
            dispatch('favlinkProvider', type)
           
        }).catch(error => {
          console.log(error)
        })
    },
    deleteLink({dispatch}, {type, vmp,payload}){
        let promise = Api()({url: `/delete-content`, data: payload, method: 'put' })
        return promise.then((resp) => {
            const msg = resp.data.result.message
            vmp.$bvToast.toast(msg, {
                title : 'OK',
                variant : 'success',
                solid : true,
              })
            dispatch('favlinkProvider', type)
        }).catch(error => {
          console.log(error)
        })
    },
    
}