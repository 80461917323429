import {UPDATE_SUGGESTION_CAT, GET_SUGGESTION_CAT, ADD_SUGGESTION_CATEGORY } from './constants'

export default {
    [`${GET_SUGGESTION_CAT}_REQUEST`] (state) {
        state.loading = true
        state.isBusy = true
        
        
    },
    [`${GET_SUGGESTION_CAT}_SUCCESS`](state, payload){
        state.loading = false
        state.suggestion = payload
        state.isBusy = false
    },
    
    [`${GET_SUGGESTION_CAT}_ERROR`](state,){
        state.loading = false
        
    },
    [`${UPDATE_SUGGESTION_CAT}_REQUEST`] (state) {
        state.loading = true
    },
    [`${UPDATE_SUGGESTION_CAT}_SUCCESS`](state, payload){
        state.loading = false
        
        state.status = payload.status
        state.message = payload.result
    },
    
    [`${UPDATE_SUGGESTION_CAT}_ERROR`](state, payload){
        state.loading = false
        state.status = payload.data.status
        state.message = payload.data.result.message
    },
    [`${ADD_SUGGESTION_CATEGORY}_REQUEST`] (state) {
        state.loading = true
    },
    [`${ADD_SUGGESTION_CATEGORY}_SUCCESS`](state, payload){
        state.loading = false
        
        state.status = payload.status
        state.message = payload.result
    },

}

