<template>
  <b-modal
      id="editlinkmodal"
      ref="modal"
      :title="modaltitle"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group id="example-input-group-2" label="categoryId" label-for="categoryId">
        <b-form-select
          id="categoryId"
          name="categoryId"
          v-model="$v.form.categoryId.$model"
          :options="categories"
          :state="validateState('categoryId')"
          aria-describedby="category-feedback"
        ></b-form-select>
        <b-form-invalid-feedback id="category-feedback">This is a required field.</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="titlegroup" label="Title" label-for="title">
        <b-form-input
          id="title"
          name="title"
          v-model="$v.form.title.$model"
          :state="validateState('title')"
          aria-describedby="title-feedback"
        ></b-form-input>

        <b-form-invalid-feedback
          id="title-feedback"
        >This is required field.</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="urlgroup" label="URL" label-for="urlinput">
        <b-input-group>
          <b-input-group-prepend v-if="type==1">
            <div style="padding: 4px 5px 0;">
              <img :src="`http://www.google.com/s2/favicons?domain=${$v.form.url.$model == '' || !$v.form.url.url ? null:$v.form.url.$model}`"  >
            </div>
          </b-input-group-prepend>
          <b-form-input
            id="urlinput"
            name="url"
            v-model="$v.form.url.$model"
            :state="validateState('url')"
            aria-describedby="url-feedback"
          ></b-form-input>
        </b-input-group>
        <b-form-invalid-feedback
          id="url-feedback"
        >This is required field.</b-form-invalid-feedback>
      </b-form-group>
     
    </b-form>
  
  </b-modal>
</template>

<style>
body {
  padding: 1rem;
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";
import { mapActions, mapState } from 'vuex'
export default {
  mixins: [validationMixin],
  props: ['type', 'modaltitle', 'handleSubmit', 'edititem', 'updateFavlink'],
  data() {
    return {
      categories: [],
      form: {
        title: null,
        categoryId: null,
        url: null,
        id: null
      }
    };
  },
  validations: {
    form: {
      categoryId: {
        required
      },
      title: {
        required,
      },
      url:{
        required, url
      }
    }
  },
  watch:{
    edititem:function(){
      const vm = this;
      vm.form = {...vm.edititem}
    }
  },
  beforeMount(){
    this.favcatProvider(this.type)
  },
  beforeUpdate(){
     let vm = this;
     const catoption = vm.favcatList.map((item) => {
        return {value:item.id, text:item.title}
      })
      vm.categories = catoption 
  },
  computed:{
    ...mapState('favoritecat',{
      favcatList: state => state.favcatList
    }),
  },
  methods: {
    ...mapActions('favoritecat',{
       favcatProvider: 'favcatProvider',
     }),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetModal() {
      

     this.$nextTick(() => { this.$v.$reset(); })
    },
    handleOk(bvModalEvt) {
        
        bvModalEvt.preventDefault()
        this.onSubmit()
      },
    onSubmit() {
      const vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      vm.updateFavlink({type:this.type,vmp:this,payload:this.form})
      this.$nextTick(() => {
          this.$bvModal.hide('editlinkmodal')
        })
    }
  },
 
};
</script>