import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueJWT from 'vuejs-jwt'
import Layout from './layout'
import router from './router'
import store from './store'
import { Vuelidate } from "vuelidate";

Vue.config.productionTip = false
Vue.use(Vuelidate);
Vue.use(VueJWT)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  render: h => h(Layout)
}).$mount('#app')
