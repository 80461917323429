//import {API_BASE_URL} from '../../../global-constant'
import {ENABLE_DISABLE_USER } from './constants'
import Api from '../../../services/api'
export default {
    updateUser({commit}, payload){
        return new Promise((resolve, reject) => {
        commit(`${ENABLE_DISABLE_USER}_REQUEST`)
        Api()({url: `/set-user-active`, data: payload, method: 'PUT' })
        .then(resp => {
            const msg = resp.data
            commit(`${ENABLE_DISABLE_USER}_SUCCESS`, msg)
            
            resolve(resp)
        })
        .catch(err => {
            commit(`${ENABLE_DISABLE_USER}_ERROR`, err.response)
            console.log(err.response)
            reject(err)
        })
        })
    },
    userProvider({commit}){
        
        commit(`${ENABLE_DISABLE_USER}_REQUEST`)
        let promise = Api().get(`/get-users`)
        return promise.then((res) => {
         
          const items = res.data.result.data
          return(items)
        }).catch(error => {
          console.log(error)
          this.isBusy
          return []
        })
    }
    
}