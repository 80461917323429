//import {API_BASE_URL} from '../../../global-constant'
import {UPDATE_SUGGESTION_CAT, GET_SUGGESTION_CAT, ADD_SUGGESTION_CATEGORY } from './constants'
import Api from '../../../services/api'
export default {
    updateFavcat({commit, dispatch}, {vmp, payload}){
        return new Promise((resolve, reject) => {
        commit(`${UPDATE_SUGGESTION_CAT}_REQUEST`)
        Api()({url: `/update-category?type=3`, data: payload, method: 'PUT' })
        .then(resp => {
            const msg = resp.data.result.message
            vmp.$bvToast.toast(msg, {
                title : 'OK',
                variant : 'success',
                solid : true,
              })
            dispatch('favcatProvider')
            commit(`${UPDATE_SUGGESTION_CAT}_SUCCESS`, msg)
            resolve(resp)
        })
        .catch(err => {
            commit(`${UPDATE_SUGGESTION_CAT}_ERROR`, err.response)
            console.error(err.response)
            reject(err)
        })
        })
    },
    favcatProvider({commit}){
        commit(`${GET_SUGGESTION_CAT}_REQUEST`)
        let promise = Api().get(`/get-category-list?type=3`)
        promise.then((res) => {
            const items = res.data.result.data
            commit(`${GET_SUGGESTION_CAT}_SUCCESS`, items)
            
        }).catch(error => {
            commit(`${GET_SUGGESTION_CAT}_ERROR`, error.response)
          console.error(error)
          
          return []
        })
    },
    addcategory({commit, dispatch}, {vmp, payload}){
        commit(`${ADD_SUGGESTION_CATEGORY}_REQUEST`)
        let promise = Api()({url: `/add-category`, data: payload, method: 'post' })
        return promise.then((resp) => {
            const msg = resp.data.result.message
            vmp.$bvToast.toast(msg, {
                title : 'OK',
                variant : 'success',
                solid : true,
              })
            dispatch('favcatProvider')
           
        }).catch(error => {
            const msg = error.response.data.result.message
            vmp.$bvToast.toast(msg, {
                title : 'Error',
                variant : 'danger',
                solid : true,
              })
        })
    },
    deleteCategory({dispatch}, {vmp, payload}){
        let promise = Api()({url: `/delete-category`, data: payload, method: 'put' })
        return promise.then((resp) => {
            const msg = resp.data.result.message
            vmp.$bvToast.toast(msg, {
                title : 'OK',
                variant : 'success',
                solid : true,
              })
            dispatch('favcatProvider')
        }).catch(error => {
            const msg = error.response.data.result.message
            vmp.$bvToast.toast(msg, {
                title : 'Error',
                variant : 'danger',
                solid : true,
              })
        })
    },
   
    
}