<template>
  <div class="home">
      <b-row style="margin-bottom:15px;">
        <b-col class="text-left"><h2 class="page-title">Manage Users</h2></b-col>
        <b-col class="text-right">
          
        </b-col>
      </b-row>
      
      <b-card class="cst-body" title="Basic and Pro users" sub-title="Below are the list of basic and pro user, you can enable and disable particular user.">
        <b-card-text>
          <div class="overflow-auto">
            

            <b-table
              id="my-table"
              :busy.sync="isBusy"
              :items="userProvider"
              :fields="fields">
              <template v-slot:cell(isActive)="row">
              
              <b-form-checkbox @change="updateUser(row.item, $event)" v-model="row.item.isActive" switch size="lg">{{row.item.isActive?'Enable':'Disabled'}}</b-form-checkbox>
            </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-card-text>
      </b-card>
  </div>
</template>

<script>
// @ is an alias to /src
//import { mapState } from 'vuex'
import { mapActions } from 'vuex'

export default {
  name: 'dashboard',
  components: {
    
  },
  data:function(){
    return {
      userDetail:{},
      isBusy:false,
      currentPage: 1,
      rows: 5,
      perPage: 10,
      fields:[
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Full Name' },
        { key: 'email', label: 'Email ID' },
        { key: 'userType', label: 'User Type' },
        { key: 'isActive', label: 'Action' },
      ]
    }
  },
  computed:{
    
  },
  methods:{
     ...mapActions('login',{
       logout: 'logout'
     }),
     ...mapActions('users',{
       updateUser: 'updateUser',
       userProvider: 'userProvider'
     })
     
  },
  created:function(){
    let vm  = this
    
    vm.userDetail = vm.$jwt.decode(vm.$jwt.getToken()).userDetail
    
  }
}
</script>
<style lang="scss" scoped>
 
</style>