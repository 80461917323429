import login from './login'
import users from './users'
import favoritecat from './favoritecat'
import favoritelink from './favoritelink'
import country from './country'
import feedcat from './feedcat'
import suggestion from './suggestion'

export default {
    namespaced: true,
    login,
    users,
    favoritecat,
    favoritelink,
    feedcat,
    country,
    suggestion
}