<template>
  <div class="home">
      <b-row style="margin-bottom:15px;">
        <b-col class="text-left"><h2 class="page-title">Country</h2></b-col>
        <b-col class="text-right">
          <b-button v-b-modal.addcountrymodal variant="info" size="sm">Add New Country</b-button>
        </b-col>
      </b-row>
      
      <b-card class="cst-body" title="Manage Country" sub-title="Below are the list of added country, you can enable, disable and delete particular country.">
        <b-card-text>
          <div class="overflow-auto">
            <b-table
              id="countrytable"
              :current-page="currentPage"
              :busy.sync="isFetching"
              :items="countries"
              :per-page="perPage"
              :fields="fields">
              <template v-slot:cell(isDefault)="row">
                <b-form-checkbox class="inlineblock" @change="enabledisableCat(row.item)" v-model="row.item.isDefault" switch size="lg">
                  {{row.item.isDefault?'Yes':'No'}}
                </b-form-checkbox>
              </template>
              <template v-slot:cell(isActive)="row">
              
              <span class="delete-btn-wrapper" @click="deleteConfirmation(row.item.id)"><b-icon-trash-fill class="delete-btn" variant="danger"></b-icon-trash-fill>Delete</span>
              <span class="delete-btn-wrapper" @click="editHandler(row.item)"><b-icon-pencil class="delete-btn" variant="info"></b-icon-pencil>Edit</span>
            </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="favoritecat"
            ></b-pagination>
          </div>
        </b-card-text>
      </b-card>
      <addcountry ref="addedit" modaltitle="Add Country Category" :editdata="editdata" :reseteditdata="reseteditdata"></addcountry>
      <!-- <addcategory modaltitle="Add Country Category" :type="'country'" :addcategoryHandler="addcountryProvider"></addcategory> -->
      <!-- <editcategory modaltitle="Edit Favorite Link"
        :edititem="edititem" 
        :updatecountry="updatecountry"></editcategory> -->
  </div>
</template>

<script>
// @ is an alias to /src
//import { mapState } from 'vuex'
import { mapActions, mapState } from 'vuex'
import addcountry from '../components/Addcountry'
/* import addcategory from '../components/Addcategory'
import editcategory from '../components/Editcategory' */

export default {
  name: 'country',
  components: {
    addcountry
  },
  data:function(){
    return {
      userDetail:{},
      isFetching: false,
      currentPage: 1,
      perPage: 10,
      editdata:{
        name: null,
        code: null,
        isDefault:false
      },
      fields:[
        { key: 'name', label: 'Country Name' },
        { key: 'code', label: 'Country Code' },
        { key: 'isDefault', label: 'Is Default ?' },
        { key: 'isActive', label: 'Action' },
      ],
    }
  },
  computed:{
    ...mapState('country',{
      isBusy: state => state.isBusy,
      countries: state => state.countries
    }),
    rows() {
        return this.countries.length
      }
    
  },
  watch:{
    isBusy:function(){
      //console.log('isbusy', this.isBusy)
      this.$root.$emit('bv::refresh::table', 'countrytable')
    }
  },
  mounted(){
    this.countryProvider({vmp:this})
    //this.$refs.$on("bv::modal::hide", this.reseteditdata)
    /* this.$refs['addedit'].$on('bv::modal::hide', () => {
      console.log('Modal is about to be shown')
    }) */
  },
  methods:{
     ...mapActions('country',{
       countryProvider: 'countryProvider',
       updatecountry: 'updatecountry',
       addcountryProvider: 'addcountry',
       deleteCountry: 'deleteCountry',

     }),
     editHandler:function(item){
       this.editdata = item;
       this.$bvModal.show('addcountrymodal')
     },
     enabledisableCat(item){
       this.updatecountry({vmp:this, payload:item})
     },
     deleteConfirmation(item) {
        this.$bvModal.msgBoxConfirm('Are you sure you want to permanently remove this item?')
          .then(value => {
            if(value){
              this.deleteCountry({vmp:this, payload:{"id":item}})
            }
          })
      },
      reseteditdata:function(){
       // console.log('hide')
        this.editdata = {
          name: null,
          code: null,
          isDefault:false
        }
      }
  },
  created:function(){
    let vm  = this
    
    vm.userDetail = vm.$jwt.decode(vm.$jwt.getToken()).userDetail
    
  }
}
</script>
<style lang="scss" scoped>
 .delete-btn{
   margin-left: 0;
 }
</style>